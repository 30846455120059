const development = {
  // BASE_URL: "http://127.0.0.1:9655/api/",
  // PUBLIC_URL: "http://127.0.0.1:8000/",
  // BASE_URL: "https://www.nfc-backend.crunchyapps.com/api/",
  // PUBLIC_URL: "https://www.nfc-backend.crunchyapps.com/",
  //BASE_URL: "https://nfc-backend.crunchyapps.com/api/",
  BASE_URL: "https://web.contactco.app/api/",
  // BASE_URL: "https://dev.innofied.org/contactwearables/public/api/",
  //PUBLIC_URL: "https://nfc-backend.crunchyapps.com/",
  PUBLIC_URL: "https://web.contactco.app/",
  // PUBLIC_URL: "https://dev.innofied.org/contactwearables/public/",
  // PUBLIC_URL: "http://127.0.0.1:9655/",
  // WEBSITE_URL: "https://nfc-frontend.crunchyapps.com/",
  //WEBSITE_URL: "https://nfc-frontend.crunchyapps.com/",
  WEBSITE_URL: "https://contactco.app/",
  //WEBSITE_URL: "https://dev.innofied.org/contactwearables/webapp/",
  // WEBSITE_URL: "https://localhost:3000/",
  AWS_URL: "https://contactwearables-bucket.s3.amazonaws.com/",
};

export default development;
