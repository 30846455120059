import React, { useState, useEffect } from "react";
import classes from "../assets/styles/Checkout.module.css";
import logo from "../assets/images/sidebar_icons/logo.svg";
import FormInput from "./Forms/FormInput";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import showToast from "../helpers/showToast";
import {
  CHECKOUT_EMAIL_EXISTS,
  CHECKOUT_PRODUCT_DETAILS,
  CHECKOUT_SUBSCRIPTION,
} from "../utils/constants/apiUrl";
import { post } from "../server";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";
// import GooglePayButton from "./GooglePayButton";
import GooglePayButton from "@google-pay/button-react";
const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#ffffff",
      fontSize: "14px",
      color: "var(--primary_text_color)",
      "::placeholder": {
        color: "var(--primary_text_color)",
      },
    },
    invalid: {
      color: "#ff0000",
    },
  },
};

const CheckoutForm = ({}) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [checkoutData, setCheckoutData] = useState();
  const [checkoutMessage, setCheckoutMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState();
  const [payBtn, setPayBtn] = useState(false);
  const [checkoutParams, setCheckoutParams] = useState({});
  const [productDetails, setProductDetails] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const productId = queryParams.get("product_id");
    const packageId = queryParams.get("package_id");
    const subscriptionType = queryParams.get("subscription_type");
    const productVariantId = queryParams.get("product_variant_id");
    const packageName = queryParams.get("package_name");
    const subscriptionPrice = queryParams.get("subscriptionPrice");
    const couponCode = queryParams.get("coupon_code") || null;
    const productPrice = queryParams.get("productPrice");
    const packageAmount =
      queryParams.get("subscriptionPrice") || checkoutData?.package_amount;

    // Store the values in state
    const params = {
      productId,
      packageId,
      subscriptionType,
      productVariantId,
      packageName,
      couponCode,
      packageAmount,
      subscriptionPrice,
      productPrice,
    };

    setCheckoutParams(params);
    localStorage.setItem("checkoutParams", JSON.stringify(params));

    // Remove query parameters from URL
    window.history.replaceState(null, "", window.location.pathname);
  }, [location.search, checkoutData?.package_amount]);

  useEffect(() => {
    if (
      checkoutParams.packageId &&
      checkoutParams.subscriptionType &&
      checkoutParams.productId
    ) {
      getProductDetails();
    }
  }, [checkoutParams]);

  const getProductDetails = async () => {
    const payload = {
      package_id: checkoutParams.packageId,
      subscription_type: checkoutParams.subscriptionType,
      product_id: checkoutParams.productId,
      product_variant_id: checkoutParams.productVariantId,
    };
    const { status, message, data } = await post(
      CHECKOUT_PRODUCT_DETAILS,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setProductDetails(data);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCustomerDetails({ ...customerDetails, [name]: value });
  };
  const handleEmailBlur = async (e) => {
    const email = e.target.value;
    if (!email) return;

    try {
      const payload = {
        email: email,
        package_id: checkoutParams.packageId,
        subscription_type: checkoutParams.subscriptionType,
        product_id: checkoutParams.productId,
        product_variant_id: checkoutParams.productVariantId,
        coupon_code: checkoutParams.couponCode,
      };
      const { status, message, data } = await post(
        CHECKOUT_EMAIL_EXISTS,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        setCheckoutData(data);
        setCheckoutMessage(message);
        showToast(true, message);
        setPayBtn(true);
      } else {
        setCheckoutData(data);
        setCheckoutMessage(message);
        showToast(false, message);
        setPayBtn(false);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);
    try {
      let stripeToken = null;
      const { error: stripeError, token } = await stripe.createToken(
        cardElement
      );
      if (stripeError) {
        setIsLoading(false);
        showToast(false, stripeError.message);
        return;
      }
      stripeToken = token.id;
      let paymentObj = {
        stripe_token: stripeToken,
        package_id: checkoutParams.packageId,
        subscription_type: checkoutParams.subscriptionType,
        amount: checkoutData.subscription_amount,
        customer_name: customerDetails.customer_name,
        email: customerDetails.email,
        product_id: checkoutParams.productId,
        product_variant_id: checkoutParams.productVariantId,
        coupon_code: checkoutData.coupon_code,
        coupon_code_id: checkoutData.coupon_code_id,
        discount: checkoutData.discount,
        discount_amount: checkoutData.discount_amount,
        original_amount: checkoutData.original_amount,
        product_amount: checkoutData.product_amount,
      };
      let { status, message, data } = await post(
        CHECKOUT_SUBSCRIPTION,
        paymentObj,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        showToast(true, message);
        window.location.href = data.shopify_checkout_url;
      } else {
        showToast(false, message);
      }
    } catch {
      //showToast(false, error.message);
      return;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  return (
    <div className={classes.checkoutWrapper}>
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-md-5">
            <img src={logo} alt="logo" />
            <h1 className="mt-4">
              US$
              {checkoutData?.subscription_amount ||
                checkoutParams?.subscriptionPrice}
            </h1>
            <div className="row align-items-center mt-2">
              <div className="col-12 col-sm-6">
                {checkoutParams?.packageName && (
                  <h2>
                    {checkoutParams?.packageName} -{" "}
                    {checkoutParams?.subscriptionType}
                  </h2>
                )}
              </div>
              <div className="col-12 col-sm-6">
                {checkoutParams?.packageAmount && (
                  <p className="text-sm-end">
                    US$
                    {checkoutParams?.packageAmount} /{" "}
                    {checkoutData?.subscription_type
                      ? checkoutData?.subscription_type
                      : checkoutParams?.subscriptionType}
                  </p>
                )}
              </div>
            </div>
            {checkoutParams?.couponCode !== null ? (
              <div className="d-flex flex-wrap justify-content-between mt-2">
                <h2>
                  Coupon Code -{" "}
                  {checkoutData?.coupon_code || checkoutParams?.couponCode}
                </h2>
                {!checkoutData ? (
                  ""
                ) : (
                  <div>
                    {checkoutData?.discount !== null ? (
                      <p className="text-light text-end">
                        Discount - {checkoutData?.discount}
                        {"%"}
                      </p>
                    ) : (
                      ""
                    )}
                    {checkoutData?.discount_amount !== null ? (
                      <p className="text-end">
                        US$
                        {checkoutData?.discount_amount} /{" "}
                        {checkoutData?.subscription_type
                          ? checkoutData?.subscription_type
                          : checkoutParams?.subscriptionType}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            <h2 className="mt-4">Next Step</h2>
            <p className="mb-2">In next step you have to pay product amount</p>
            <div className="d-flex flex-wrap align-items-center justify-content-between mt-2">
              <div className="d-flex flex-wrap align-items-center">
                <img
                  className={`${classes.productImg} me-3`}
                  src={productDetails?.product?.image?.src}
                  alt={productDetails?.product?.title}
                />
                <div>
                  <h2>{productDetails?.product?.title}</h2>
                  <p>US$ {productDetails?.product_amount}</p>
                </div>
              </div>
              {console.log(
                "sdfbjnv",
                productDetails?.product_discount !== null ? "abc" : "cde"
              )}
              {productDetails?.product_discount !== null ? (
                <div>
                  <p className="text-light text-end">
                    Discount - {productDetails?.product_discount?.discount}
                    {productDetails?.product_discount?.discount_type ===
                    "percentage"
                      ? "%"
                      : productDetails?.product_discount?.discount_type ===
                        "amount"
                      ? " Flat off"
                      : ""}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <h2 className={`mt-4 ${classes.message}`}>
              {checkoutMessage ? `NOTE: ${checkoutMessage}` : ""}
            </h2>
          </div>
          <div className="col-md-5 offset-md-2">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <FormInput
                  type="email"
                  label="Email"
                  placeholder="Enter your email id"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleEmailBlur}
                />
              </div>
              <div className="mb-3">
                <label className={classes.formLabel}>Card Information</label>
                <div
                  style={{
                    border: "2px solid var(--border_color)",
                    padding: "10px",
                    borderRadius: "50px",
                    backgroundColor: "var(--secondary_color)",
                  }}
                  className="cardElementWrapper"
                >
                  <CardElement id="card-element" options={cardElementOptions} />
                </div>
              </div>
              <div className="mb-4">
                <FormInput
                  type="text"
                  label="Cardholder Name"
                  placeholder="Enter full name on card"
                  name="customer_name"
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                className={`btn btn-primary ${
                  payBtn === false ? "d-none" : "d-block"
                }`}
              >
                Pay Now
              </button>
            </form>
            {/* <GooglePayButton
              environment="TEST"
              buttonSizeMode="fill"
              paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                  {
                    type: "CARD",
                    parameters: {
                      allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                      allowedCardNetworks: ["MASTERCARD", "VISA"],
                    },
                    tokenizationSpecification: {
                      type: "PAYMENT_GATEWAY",
                      parameters: {
                        gateway: "stripe", // Replace with your gateway, e.g., "stripe", "braintree"
                        gatewayMerchantId: "BCR2DN4T2OH6PRBV",
                      },
                    },
                  },
                ],
                merchantInfo: {
                  merchantId: "17613812255336763067", // Replace with your Google merchant ID
                  merchantName: "Demo Only",
                },
                transactionInfo: {
                  totalPriceStatus: "FINAL",
                  totalPriceLabel: "Total",
                  totalPrice: "10.00", // Example amount, replace with your dynamic total
                  currencyCode: "IN",
                  countryCode: "INR",
                },
              }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
