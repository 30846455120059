import React, { useEffect, useState, useMemo } from "react";
import classes from "../../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { get, deleteRecord, put } from "../../../server";
import { PRODUCT_DISCOUNT } from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import { useParams } from "react-router-dom";
const AllProductDiscount = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [errors, setErrors] = useState({});
  const [discounts, setDiscounts] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [statusId, setStatusId] = useState(null);
  let { id } = useParams();
  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const loadProductDiscount = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      PRODUCT_DISCOUNT,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      // if (data.length > 0) {
      setDiscounts(data);
      const status = data.map((statusValue) => statusValue.status);
      setStatusId(status);
      // }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    const filtered = discounts.filter(
      (item) =>
        `${item.name || ""}`.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.package.package_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.discount || ""}`.includes(filterText)
    );

    return filtered;
  }, [filterText, discounts]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Discount Name",
      width: "150px",
      cell: (row) => <p className="globalDetail">{row.name}</p>,
    },
    {
      name: "Package",
      width: "120px",
      cell: (row) => <p className="globalDetail">{row.package.package_name}</p>,
    },
    {
      name: "Duration",
      width: "150px",
      cell: (row) => (
        <p className="globalDetail">
          {row.duration_type.charAt(0).toUpperCase() +
            row.duration_type.slice(1)}
        </p>
      ),
    },
    {
      name: "Discount",
      cell: (row) => (
        <p className="globalDetail">
          {row.discount_type === "percentage" ? (
            `${row.discount}%`
          ) : (
            <>
              ${row.discount}
              {/* <small> Flat Off</small> */}
            </>
          )}
        </p>
      ),
    },
    {
      name: "Status",
      width: "200px",
      //cell: (row) => <p className="globalDetail">{row.status}</p>,
      cell: (row) => {
        const statusText = row.status === 1 ? "Active" : "Inactive";
        return <p className="globalDetail">{statusText}</p>;
      },
    },
    {
      name: "Start Date",
      width: "200px",
      cell: (row) => {
        const creationDate = new Date(row.start_date);
        const formattedDate = creationDate.toLocaleString();
        return <p className="globalDetail">{formattedDate}</p>;
      },
    },
    {
      name: "End Date",
      width: "200px",
      cell: (row) => {
        const creationDate = new Date(row.end_date);
        const formattedDate = creationDate.toLocaleString();
        return <p className="globalDetail">{formattedDate}</p>;
      },
    },
    {
      name: "Action",
      width: "100px",
      right: "true",
      cell: (row, index) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link
                className="dropdown-item"
                to={`/productDiscount/addEditProductDiscount/${row.id}`}
              >
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => updateStatus(row.id)}
              >
                {/* Inactive */}
                {statusId[index] === 1 ? "Inactive" : "Active"}
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (discountId) => {
    setDeleteId(discountId);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${PRODUCT_DISCOUNT}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await loadProductDiscount();
      setLoading(false);
    }
    setLoading(false);
  };

  const updateStatus = async (discountId) => {
    setLoading(true);
    setStatusId(discountId);
    let newStatus = 1;
    const discountIndex = discounts.findIndex((pkg) => pkg.id === discountId);
    if (discountIndex !== -1) {
      if (statusId[discountIndex] === 1) {
        newStatus = 0;
      }
    }
    const payload = {
      status: newStatus,
    };
    let { status, message } = await put(
      `${PRODUCT_DISCOUNT}/${discountId}/update/status`,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await loadProductDiscount();
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadProductDiscount();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Product Discount <span>({discounts.length})</span>
              </p>
              <div>
                <div className="d-flex align-items-center justify-content-end gap-2 mb-2">
                  <Link to="/productDiscount/addEditProductDiscount">
                    <button className="primary_button">Add</button>
                  </Link>
                </div>
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              theme="solarized"
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="coupon"
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AllProductDiscount;
